import {useRef, useEffect} from 'react';
import {Feature_collections, Hero} from '../../components/component';
import Meta from '../../components/Meta';

const Home_1 = () => {
  const scrollRef = useRef(null);

  const scrollToElement = () => {
    scrollRef.current?.scrollIntoView(true, { behavior: 'smooth' });
  };

  useEffect(() => {
    const timeout = setTimeout(() => scrollToElement(), 2000);

    return () => {
      clearTimeout(timeout);
    }
  }, []);
  return (
    <main>
      <Meta title='Home' />
      <Hero />
      <Feature_collections bgWhite={true}/>
      <div ref={scrollRef} />
    </main>
  );
};

export default Home_1;
